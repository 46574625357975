import Vue from 'vue';
import VueRouter from 'vue-router';

import uploadvideo from '@/components/vploadvideo.vue';
import tuwen from '@/components/tuwen.vue';
import tablist from '@/components/taglist.vue';
import tupian from '@/components/tupian.vue';
import shangcheng from '@/components/shangcheng.vue';
import roule from '@/components/roule.vue';
Vue.use(VueRouter);

const routes = [{
	path: '/uploadvideo',
	name: 'uploadvideo',
	component: uploadvideo,
}, {
	path: '/tuwen',
	name: 'tuwen',
	component: tuwen,
}, {
	path: '/tablist',
	name: 'tablist',
	component: tablist,
}, {
	path: '/tupian',
	name: 'tupian',
	component: tupian,
}, {
	path: '/shangcheng',
	name: 'shangcheng',
	component: shangcheng,
}, {
	path: '/roule',
	name: 'roule',
	component: roule,
}];

const router = new VueRouter({
	routes
});

export default router;