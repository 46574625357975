<template>
	<div class="shangcheng">
		<div class="top_content">
			<div class="left_icon" @click="gotoback">
				<img src="../assets/back.png" alt="" />
			</div>
			<div class="center_content">发布商品</div>
			<div class="rules" @click="pageroule">发布规则</div>
		</div>

		<div class="inputbox">
			<div class="biaoqian" @click="selecttag">
				<span>{{ selecttext }}</span>
				<div class="biaoqiaoleft">
					<img src="../assets/leftjiantou.png" alt="" />
				</div>
			</div>
			<div class="item">
				<input v-model="pagebox.forumTitle" maxlength="50" class="inputcontent" placeholder="请输入标题" type="text" />
				<span class="zishu">{{ pagebox.forumTitle.length }}/50</span>
			</div>
			<div class="item">
				<textarea v-model="pagebox.forumContent" maxlength="2000" class="textareacontent" placeholder="请填写正文(选填)" type="text" />
				<span class="zishu">{{ pagebox.forumContent.length }}/2000</span>
			</div>
			<div class="item">
				<input placeholder="请设置金币0~100,不设置则为免费" v-model="pagebox.gold" class="inputcontent" type="number" />
			</div>
			<div class="item">
				<input placeholder="请填写联系方式(必填)" v-model="pagebox.contactAddress" class="inputcontent" type="text" />
			</div>
		</div>

		<div class="shagnchuangtupian">
			<div class="u_title">
				添加图集
				<span>最多只能添加8张图片，每张最大1M以内</span>
			</div>

			<div class="sc_boxboxbox">
				<div class="imglistbox">
					<div class="img_item" v-for="(item, index) in imgurldata">
						<div class="shanchuicon" @click="delimgdata(index)">
							<img style="width: 20px; height: 20px" src="../assets/delicon.png" alt="" />
						</div>
						<img :src="item" alt="" />
					</div>
				</div>

				<label class="topcontent">
					<input @change="handleimglist" multiple accept="image/*" type="file" id="btnAdd" name="btnAdd" style="display: none" />

					<div class="uploadcontent">
						<div class="up_text">添加图片</div>
					</div>
				</label>
			</div>
		</div>

		<div class="shagnchuangtupian">
			<div class="u_title">
				添加视频
				<span>只能添加一个视频，没有封面显示视频第一帧</span>
			</div>

			<div class="scboxbox">
				<label class="topcontent">
					<input @change="handleFileUpload" accept="video/*" type="file" id="btnAdd" name="btnAdd" style="display: none" />
					<div class="uploadcontent">
						<div class="up_text">添加视频</div>
						<div class="selected_video">
							{{ filedata.name }}
						</div>
					</div>
				</label>

				<div class="sc_boxboxbox">
					<div class="imglistbox" v-if="coverurldata.length > 0">
						<div class="img_item">
							<div class="shanchuicon" @click="delcoverdata">
								<img style="width: 20px; height: 20px" src="../assets/delicon.png" alt="" />
							</div>
							<img :src="coverurldata" alt="" />
						</div>
					</div>

					<label class="topcontent">
						<input @change="handleimgfengmian"  accept="image/*" type="file" id="btnAdd" name="btnAdd" style="display: none" />

						<div class="uploadcontent">
							<div class="up_text">上传封面</div>
							<div class="selected_video">
								{{ coverimgdata.name }}
							</div>
						</div>
					</label>
				</div>
			</div>
		</div>

		<div class="tipsbox">
			<p>1.视频不能超过200M</p>
			<p>2.上传完后需要等待审核</p>
		</div>

		<div class="bottom_button">
			<div class="upbtn" @click="uplofile">上传文件</div>
		</div>
		
		
		<div class="popus" v-if="showupbar">
			<p style="margin-bottom: 10px">等待上传中：{{progressbar.toFixed(0)}}%</p>
			<img class="loadicon" src="../assets/loading.png" alt="" />
		</div>
		

		<div class="popus" v-if="zhuanmazhong">
			<p>正在转码中...</p>
		</div>

		<div class="upimgtipstext" v-if="imgerror">请选择要上传的视频</div>
		<div class="uptipstext" v-if="texterrox">请输入帖子信息</div>
		<div class="successtext" v-if="upsuccess">上传成功,等待发布帖子</div>
		<div class="fabutext" v-if="fabusuccess">帖子发布成功,等待审核!</div>
		<div class="errortext" v-if="uperror">上传失败</div>

		<div class="errortext" v-if="upnum">今日上传剩余次数不足</div>
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import rsa from '@/rsa/rsa.js';
import webview from '@/rsa/webview.js';

export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
		return {
			selecttext: '选择帖子板块',
			uploadstatus: true,
			checkboxs: [],
			yhdata: '',
			currimgindex: 0,

			//uploadurl: 'http://videoupload.appinfo.ltd:2100',
			uploadurl: 'https://videoupload.appinfo.ltd',
			
			baseurl: 'https://api.appinfo.live',
			filedata: '',
			imgdata: [],
			imgurldata: [],
			coverimgdata: [],
			coverurldata: [],
			checked: false,
			UserId: '',
			Token: '',
			UserVid: '',
			progressbar: 0,
			upnum: false,

			showupbar: true,
			upsuccess: false,
			uperror: false,
			zhuanmazhong: false,
			fabusuccess: false,
			fabuzhuangtai: true,
			texterrox: false,
			imgerror: false,
			pagebox: {
				forumTitle: '',
				forumContent: '',
				gold: '',
				taggingId: '',
				videoPreviewURL: '',
				videoM3U8URL: '',
				duration: '',
				imageURL: '',
				forumType: 3,
				contactAddress: ''
			},
			uploaddata: '',
			upimgresarray: []
		};
	},

	watch: {
		'pagebox.gold': {
			handler: function (newValue, oldValue) {
				
				if (newValue > 10) {
					this.pagebox.gold = 0;
				}
			},
			deep: true
		}
	},

	mounted() {
		if (this.$route.params.id) {
			this.pagebox.taggingId = this.$route.params.id;
			this.selecttext = this.$route.params.title;

			this.UserId = this.$route.params.UserId;
			this.Token = this.$route.params.Token;
			this.UserVid = this.$route.params.UserVid;
		}
		if (this.$route.params.UserId) {
			this.UserId = this.$route.params.UserId;
			this.Token = this.$route.params.Token;
			this.UserVid = this.$route.params.UserVid;
		}
	},
	methods: {
		//发布规则
		pageroule() {
			this.$router.push({
				path: '/roule',
				query: {
					type: 0,
					UserId: this.UserId,
					Token: this.Token,
					UserVid: this.UserVid,
					pagetype: 0,
					taggingType: 0
				}
			});
		},
		gotoback() {
			webview.postMessage({
				data: '发布成功'
			});
		},

		//选择标签
		selecttag() {
			this.$router.replace({
				path: '/tablist',
				query: {
					type: 0,
					UserId: this.UserId,
					Token: this.Token,
					UserVid: this.UserVid,
					pagetype: 3,
					taggingType: 1
				}
			});
		},

		//获取视频标签
		// gettabslist() {
		// 	let _this = this;
		// 	let header = '';
		// 	let InPutData = {
		// 		UserId: this.UserId,
		// 		Token: this.Token,
		// 		Type: '1'
		// 	};
		// 	header = {
		// 		InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
		// 	};
		// 	axios.get(this.baseurl + '/api/UserForum/GetTaggingLabels', { headers: header }).then((res) => {
		// 		if (res.data.code == 200) {
		// 			let taglist = res.data.data;
		// 			_this.checkboxs = [];
		// 			for (let index in taglist) {
		// 				taglist[index]['checked'] = false;

		// 				_this.checkboxs.push(taglist[index]);
		// 			}
		// 		}
		// 	});
		// },

		//获取用户上传信息
		uploaddetails() {
			let _this = this;
			let header = '';
			let InPutData = {
				UserId: this.UserId,
				Token: this.Token,
				Type: '1'
			};
			header = {
				InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
			};

			axios.get(this.baseurl + '/api/UserHandle/GetUserData', { headers: header }).then((res) => {
				if (res.data.code == 200) {
					this.yhdata = res.data.data;
				}
			});
		},

		//删除图片
		delimgdata(index) {
			this.imgdata.splice(index, 1);
			this.imgurldata.splice(index, 1);
		},

		//获取上传的图片集合
		handleimglist(e) {
			const imgarray = e.target.files;
			const URL = window.URL || window.webkitURL; // 当前地址
			for (let index in imgarray) {
				if (this.imgdata.length < 8) {
					if (imgarray[index].lastModified) {
						this.imgdata.push(imgarray[index]);
						this.imgurldata.push(URL.createObjectURL(imgarray[index]));
					}
				}
			}
		},
		//删除封面图
		delcoverdata(){
			this.coverdata=[];
			this.coverurldata=[];
		},
		//获取封面文件
		handleimgfengmian(e) {
			const URL = window.URL || window.webkitURL; // 当前地址
			this.coverimgdata = [];
			this.coverimgdata.push(e.target.files[0]);
			this.coverurldata=URL.createObjectURL(e.target.files[0]);
		},

		//获取上传的文件
		handleFileUpload(e) {
			this.filedata = e.target.files[0];
		},

		//对视屏进行切片
		slicevideo(file) {
			return new Promise((resolve, reject) => {
				let chunkSize = 10 * 1024 * 1024; // 每个块的大小为 1MB
				let fileSize = file.size; // 文件大小
				let chunks = Math.ceil(fileSize / chunkSize); // 总块数
				let tasks = []; // 切块
				// 文件切割、
				for (let i = 0; i < chunks; i++) {
					let start = i * chunkSize;
					let end = Math.min(start + chunkSize, fileSize);
					tasks.push(file.slice(start, end));
				}
				resolve(tasks);
			});
		},

		//获取文件的md5值
		fileMd5Sum(file) {
			return new Promise((resolve) => {
				let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
				let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
				let fileReader = new FileReader();
				let Spark = new SparkMD5.ArrayBuffer();
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = function (e) {
					Spark.append(e.target.result);
					let md5 = Spark.end();
					let fileboxdata = {
						md5: md5,
						fileName: fileName,
						fileExtension: fileExtension
					};
					resolve(fileboxdata);
				};
			});
		},

		//大文件是否存在校验
		jianchefile(status, md5, uploadkey) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: status,
						md5: md5,
						uploadkey: uploadkey
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//大文件的分割的切片是否存在
		jianchefilesoon(chunkIndex, name, size) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunkCheck',
						chunkIndex: chunkIndex,
						name: name,
						size: size
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传视频分片
		upvideo(md5, index, fileitem) {
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', index);
				formData.append('file', fileitem);
				axios
					.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//上传图片文件
		upimgfile(md5, fileitem) {
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', 0);
				formData.append('file', fileitem);
				axios
					.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//合并视屏切片
		joinvideo(md5, chunkslength, fileName, fileExtension) {
			let that = this;
			return new Promise((resolve, reject) => {
				let obj = {
					status: 'chunksMerge',
					md5: md5,
					chunks: chunkslength,
					name: md5,
					fileoldname: fileName,
					ext: fileExtension
				};
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunksMerge',
						md5: md5,
						chunks: chunkslength,
						name: md5,
						fileoldname: fileName,
						ext: fileExtension
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传文件
		async uplofile() {
			if (this.uploadstatus) {
				this.uploadstatus = false;

				if (!this.pagebox.forumTitle) {
					this.texterrox = true;
					setTimeout(() => {
						this.texterrox = false;
						this.uploadstatus = true;
					}, 2000);
					return;
				}

				if (!this.pagebox.contactAddress) {
					this.texterrox = true;
					setTimeout(() => {
						this.texterrox = false;
						this.uploadstatus = true;
					}, 2000);
					return;
				}

				//上传图片集合
				if (this.imgdata.length != 0) {
					const imglistfile = this.imgdata;
					this.upimgresarray = [];
					for (let index in imglistfile) {
						//获取图片md5信息
						let imgmd5mesgs = await this.fileMd5Sum(imglistfile[index]);
						//检测视频是否存在 不存在就上传
						let imgfileres = await this.jianchefile('md5Check', imgmd5mesgs.md5, 'sdqutyeksdbv855');
						//上传图片
						let upimgres = await this.upimgfile(imgmd5mesgs.md5, imglistfile[index]);
						//合并上传图片
						let upimgjieguo = await this.joinvideo(imgmd5mesgs.md5, 1, imgmd5mesgs.fileName, imgmd5mesgs.fileExtension);
						if (upimgjieguo.error == 'undefined' || upimgjieguo.error == undefined) {
							let imageURL = upimgjieguo.url;
							let imagestr = imageURL.split('/');
							let imageresult = '';
							if (imagestr.length >= 3) {
								for (let i = 3; i < imagestr.length; i++) {
									if (imageresult !== '') {
										imageresult += '/';
									}
									imageresult += imagestr[i];
								}
							} else {
								
							}
							this.upimgresarray.push(imageresult);
							if (!this.filedata) {
								this.fabuvideo();
							}
						}
					}
				}

				//上传封面图
				if (this.coverimgdata) {
					const imglistfile = this.coverimgdata;
					for (let index in imglistfile) {
						//获取图片md5信息
						let imgmd5mesgs = await this.fileMd5Sum(imglistfile[index]);
						//检测视频是否存在 不存在就上传
						let imgfileres = await this.jianchefile('md5Check', imgmd5mesgs.md5, 'sdqutyeksdbv855');
						//上传图片
						let upimgres = await this.upimgfile(imgmd5mesgs.md5, imglistfile[index]);
						//合并上传图片
						let upimgjieguo = await this.joinvideo(imgmd5mesgs.md5, 1, imgmd5mesgs.fileName, imgmd5mesgs.fileExtension);
						if (upimgjieguo.error == 'undefined' || upimgjieguo.error == undefined) {
							let imageURL = upimgjieguo.url;
							let imagestr = imageURL.split('/');
							let imageresult = '';
							if (imagestr.length >= 3) {
								for (let i = 3; i < imagestr.length; i++) {
									if (imageresult !== '') {
										imageresult += '/';
									}
									imageresult += imagestr[i];
								}
							} else {
								
							}
							this.pagebox.videoPreviewURL = imageresult;
						}
					}
				}

				//上传视频
				if (this.filedata) {
					this.showupbar = true;
					//获取文件md5
					let md5mesgs = await this.fileMd5Sum(this.filedata);
					//对视屏进行切片
					let splitvideo = await this.slicevideo(this.filedata);
					//检测视频是否存在 不存在就上传
					let fileres = await this.jianchefile('md5Check', md5mesgs.md5, 'sdqutyeksdbv855');
					for (let index in splitvideo) {
						//检测视频的分片 是否存在
						let slicefileres = await this.jianchefilesoon(index, md5mesgs.md5, splitvideo[index].size);
						let upres = await this.upvideo(md5mesgs.md5, index, splitvideo[index]);
						this.progressbar = (index / (splitvideo.length - 1)) * 100;
						if (index == splitvideo.length - 1 && upres.data == 'ok') {
							let hebingjieguo = await this.joinvideo(md5mesgs.md5, splitvideo.length, md5mesgs.fileName, md5mesgs.fileExtension);
							if (hebingjieguo.error != 'undefined' && hebingjieguo.error != undefined) {
								this.showupbar = false;
								this.uperror = true;
								this.uploadstatus = true;
								setTimeout(() => {
									this.uperror = false;
								}, 2000);
							} else {
								this.pagebox.duration = hebingjieguo.duration;
								let m3u8result = '';
								let m3u8url = hebingjieguo.url;
								let m3u8str = m3u8url.split('/');

								for (let i = 3; i < m3u8str.length; i++) {
									if (m3u8result !== '') {
										m3u8result += '/';
									}
									m3u8result += m3u8str[i];
								}
								if (this.pagebox.videoPreviewURL == '') {
									let picresult = '';
									let picurl = hebingjieguo.pic;
									let picstr = picurl.split('/');
									for (let i = 3; i < picstr.length; i++) {
										if (picresult !== '') {
											picresult += '/';
										}
										picresult += picstr[i];
									}
									this.pagebox.videoPreviewURL = picresult;
								}

								this.pagebox.videoM3U8URL = m3u8result;
								this.uploaddata = hebingjieguo;
								//选择封面图
								this.showupbar = false;
								this.fabuvideo();
							}
						}
					}
				}
			}
		},

		//选择图片
		handleselectimg(index) {
			this.currimgindex = index;
		},

		//选择标签
		handletab(itemvalue, index) {
			let total = 0;
			let checkboxs = this.checkboxs;
			checkboxs[index].checked = !checkboxs[index].checked;
			for (let souying in checkboxs) {
				if (checkboxs[souying].checked) {
					total = total + 1;
					if (total > 3) {
						checkboxs[souying].checked = false;
					}
				}
			}
		},

		//发布社区
		fabuvideo() {
			let _this = this;
			if (_this.fabuzhuangtai) {
				_this.fabuzhuangtai = false;
				let header = '';
				let InPutData = {
					UserId: _this.UserId,
					Token: _this.Token,
					Type: '1'
				};
				header = {
					InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
				};
				axios
					.post(
						_this.baseurl + '/api/UserForum/PublishUserForum',
						{
							forumTitle: this.pagebox.forumTitle,
							videoPreviewURL: this.pagebox.videoPreviewURL,
							videoM3U8URL: this.pagebox.videoM3U8URL,
							duration: this.pagebox.duration,
							imageURL: this.upimgresarray.toString(),
							taggingId: this.pagebox.taggingId,
							forumType: 3,
							forumContent: this.pagebox.forumContent,
							gold: this.pagebox.gold == '' ? 0 : this.pagebox.gold,
							contactAddress: this.pagebox.contactAddress
						},
						{
							headers: header
						}
					)
					.then((res) => {
						this.uploadstatus = true;
						if (res.data.code == 200) {
							_this.fabusuccess = true;
							setTimeout(() => {
								_this.fabusuccess = false;
							}, 2000);
							setTimeout(() => {
								webview.postMessage({
									data: '发布成功'
								});
							}, 1500);
						} else {
							_this.fabuzhuangtai = true;
							_this.uperror = true;
							setTimeout(() => {
								_this.uperror = false;
							}, 2000);
						}
					})
					.catch((error) => {
						console.error(error);
					});
			}
		}
	}
};
</script>
<style scoped>
* {
	padding: 0;
	margin: 0;
}
.shangcheng {
	padding-top: 80px;

	overflow-y: scroll;
}
.inputbox .item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	width: 325px;
	margin-bottom: 10px;
}

.inputbox .item .zishu {
	position: absolute;
	right: -22px;
	bottom: 2px;
	font-size: 11px;
	color: #999;
}

.top_content {
	position: fixed;
	left: 0;
	right: 0;
	top: 0px;
	z-index: 99;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
	flex-direction: row;
	background-color: #f5f5f5;
	padding-top: 40px;
}
.left_icon {
	width: 20px;
	height: 20px;
	display: flex;
}
.left_icon img {
	width: 20px;
	height: 20px;
}

.center_content {
	font-size: 20px;
	font-weight: bold;
	padding-left: 110px;
}
.rules {
	font-size: 14px;
	position: absolute;
	right: 20px;
	bottom: 10px;
	color: blue;
	font-weight: bold;
}
.shagnchuangtupian .u_title span {
	color: red;
	font-size: 12px;
	font-weight: normal;
}
.shagnchuangtupian .u_title {
	font-size: 15px;
	text-align: left;
	padding-bottom: 10px;
	color: #000;
	font-weight: bold;
	padding-left: 5px;
	position: relative;
}
.shagnchuangtupian .scboxbox {
	display: flex;
}
.shagnchuangtupian .uploadcontent {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	background-color: #f5f5f5;
	position: relative;
	margin-right: 20px;
}
.uploadcontent::after {
	position: absolute;
	content: '';
	width: 25px;
	height: 2px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.uploadcontent::before {
	position: absolute;
	content: '';
	width: 2px;
	height: 25px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.shagnchuangtupian .up_text {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	white-space: nowrap;
	font-size: 13px;
	color: #999;
	z-index: 99;
}
.shagnchuangtupian .selected_video {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;
	white-space: nowrap;
	font-size: 12px;
	z-index: 99;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 80px;
	color: red;
}

.sc_boxboxbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox .img_item {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
}
.imglistbox .img_item img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}
.shanchuicon {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: flex;
}

.shagnchuangtupian .topcontent {
	width: 100px;
	height: 100px;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	margin-right: 10px;
}

.shagnchuangtupian {
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	position: relative;
}

.inputcontent {
	width: 335px;
	height: 30px;

	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: #f5f5f5;
}
.textareacontent {
	padding-top: 10px;
	padding-bottom: 10px;
	width: 335px;
	height: 100px;
	padding-left: 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: #f5f5f5;
	font-size: 13px;
	padding-right: 10px;
}

.biaoqian {
	height: 35px;
	position: relative;
	display: flex;
	width: 355px;
	height: 30px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: #f5f5f5;
	margin-bottom: 10px;
}
.biaoqian span {
	position: absolute;
	left: 15px;
	font-size: 13px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
}
.biaoqian .biaoqiaoleft {
	position: absolute;
	width: 20px;
	height: 20px;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	img {
		width: 20px;
		height: 20px;
	}
}
.tipsbox {
	display: flex;
	flex-direction: column;
}
.tipsbox p {
	font-size: 11px;
	color: #999;
	text-align: left;
	line-height: 20px;
	display: flex;
	align-items: center;
}
.tipsbox p img {
	width: 12px;
	height: 12px;
	margin-left: 2px;
	display: flex;
}

.bottom_button {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	height: 80px;
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	background-color: #fff;
	z-index: 66;
}
.bottom_button .upbtn {
	height: 44px;
	border-radius: 16px;
	width: 301px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 16px auto 0 auto;
	background-color: red;
}
.fabushortvideo {
	height: 44px;
	border-radius: 16px;
	width: 301px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 16px auto 0 auto;
	background-color: red;
}

.uplist {
	display: flex;
	flex-wrap: wrap;
}
.uplist .up_item {
	width: 60px;
	height: 100px;
	margin-top: 10px;
	margin-left: 5px;
	border: 1px solid #fff;
}
.uplist .select_item {
	border: 1px solid red;
}
.uplist .up_item img {
	width: 60px;
	height: 100px;
}

.popus {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 16px;
	flex-direction: column;
}
.popus .jingdutiao {
	width: 250px;
	height: 12px;
	border: 1px solid #fff;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}
.popus .jingdutiao span {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: gold;
}

.popus .loadicon {
	width: 40px;
	height: 40px;
	-webkit-transform: rotate (360deg);
	animation: rotation 1s linear infinite;
	-moz-animation: rotation 1s linear infinite;
	-webkit-animation: rotation 1s linear infinite;
	-o-animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

.transcoding {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.uptipstext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.upimgtipstext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.successtext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.fabutext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.errortext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}

.img_box {
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	color: #666;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 140px;
}
</style>
