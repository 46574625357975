<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script type="text/javascript" src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"></script>
<script>
export default {
	name: 'App',
	data() {
		return {};
	},
	created() {},
	mounted() {
		const params = new URLSearchParams(window.location.search);
		const pagenums = params.get('pagenum');
		const Tokens = params.get('Token');
		const UserIds = params.get('UserId');
		const UserVids = params.get('UserVid');

		if (pagenums == 1) {
			this.$router.replace({
				name: 'uploadvideo',
				params: {
					UserId: UserIds,
					Token: Tokens,
					UserVid: UserVids
				}
			});
			console.log('uploadvideo');
		} else if (pagenums == 0) {
			this.$router.replace({
				name: 'tupian',
				params: {
					UserId: UserIds,
					Token: Tokens,
					UserVid: UserVids
				}
			});
			console.log('tupian');
		} else if (pagenums == 2) {
			this.$router.replace({
				name: 'tuwen',
				params: {
					UserId: UserIds,
					Token: Tokens,
					UserVid: UserVids
				}
			});
			console.log('tuwen');
		} else if (pagenums == 3) {
			this.$router.replace({
				name: 'shangcheng',
				params: {
					UserId: UserIds,
					Token: Tokens,
					UserVid: UserVids
				}
			});
			console.log('shangcheng');
		}
	}
};
</script>

<style>
* {
	padding: 0;
	margin: 0;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	padding: 0;
	margin: 0;
}

::-webkit-scrollbar {
	display: none;
}
</style>
