<template>
	<div class="tablist">
		<div class="top_content">
			<div class="left_icon" @click="backpage">
				<img src="../assets/back.png" alt="" />
			</div>
			<div class="center_content">选择板块</div>
		</div>

		<div class="list">
			<div class="boxboxbox" v-for="item in tagdata" @click="backpage(item)">
				<div class="l_itme">
					<div class="l_img">
						<img :src="imgurl + item.TaggingImage" alt="" />
					</div>
					<div class="l_content">
						<p class="p1">{{ item.TaggingTitle }}</p>
						<p class="p2">帖子{{ item.TaggingCount }} 浏览{{ item.BrowseCount }}</p>
					</div>
					<div class="select">选择</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import rsa from '@/rsa/rsa.js';
import webview from '@/rsa/webview.js';

export default {
	props: {
		msg: String
	},
	data() {
		return {
			uploadurl: 'https://videoupload.appinfo.ltd',
			baseurl: 'https://api.appinfo.live',
			imgurl: 'https://upload.appinfo.live/',
			UserId: '',
			Token: '',
			UserVid: '',
			taggingType: 0,
			tagdata: '',
			pagetype: ''
		};
	},

	mounted() {
		this.pagetype = this.$route.query.pagetype;
		
		
		this.taggingType = this.$route.query.taggingType;
		this.UserId = this.$route.query.UserId;
		this.Token = this.$route.query.UserId;
		this.UserVid = this.$route.query.UserVid;
	
		this.gettabslist();
	},
	methods: {
		//获取社区标签列表
		gettabslist() {
			let _this = this;
			let header = '';
			let InPutData = {
				UserId: this.UserId,
				Token: this.Token,
				Type: '1'
			};
			header = {
				InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
			};

			if (this.UserVid == 3) {
				this.taggingType = 2;
			}

			axios.get(this.baseurl + '/api/UserForum/GetTaggingLabels?taggingType=' + this.taggingType, { headers: header }).then((res) => {
				if (res.data.code == 200) {
					this.tagdata = res.data.data;
				}
			});
		},

		gotoback() {
			this.$router.go(-1);
		},
		backpage(itemvalue) {
			if (this.pagetype == 0) {
				this.$router.replace({
					name: 'tupian',
					params: {
						id: itemvalue.TaggingId,
						title: itemvalue.TaggingTitle,
						UserId: this.UserId,
						Token: this.Token,
						UserVid: this.UserVid,
						taggingType: 0
					}
				});
			} else if (this.pagetype == 1) {
				this.$router.replace({
					name: 'uploadvideo',
					params: {
						id: itemvalue.TaggingId,
						title: itemvalue.TaggingTitle,
						UserId: this.UserId,
						Token: this.Token,
						UserVid: this.UserVid,
						taggingType: 0
					}
				});
			} else if (this.pagetype == 2) {
				this.$router.replace({
					name: 'tuwen',
					params: {
						id: itemvalue.TaggingId,
						title: itemvalue.TaggingTitle,
						UserId: this.UserId,
						Token: this.Token,
						UserVid: this.UserVid,
						taggingType: 0
					}
				});
			} else if (this.pagetype == 3) {
				this.$router.replace({
					name: 'shangcheng',
					params: {
						id: itemvalue.TaggingId,
						title: itemvalue.TaggingTitle,
						UserId: this.UserId,
						Token: this.Token,
						UserVid: this.UserVid,
						taggingType: 1
					}
				});
			}
		}
	}
};
</script>
<style scoped>
* {
	padding: 0;
	margin: 0;
}
.tablist {
	
	background-color: #090216;
	height: 100vh;
}
.top_content {
	position: fixed;
	left: 0;
	right: 0;
	top: 35px;

	z-index: 99;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
}
.left_icon {
	width: 20px;
	height: 20px;
	display: flex;
}
.left_icon img {
	width: 20px;
	height: 20px;
}
.center_content {
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
}

.list {
	padding-top: 85px;
	
}
.list .l_itme {
	padding: 10px 10px;
	border-radius: 10px;
	background-color: #eee;
	display: flex;
	align-items: center;
	flex-direction: row;
	position: relative;
	margin-top: 15px;
	width: 325px;
	margin: 15px auto 0 auto;
}

.l_img {
	width: 95px;
	height: 65px;
}
.l_img img {
	width: 95px;
	height: 65px;
	border-radius: 5px;
}
.l_content {
	padding-left: 20px;
	text-align: left;
}
.l_content .p1 {
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 5px;
}

.l_content .p2 {
	font-size: 12px;
	color: #999;
}

.select {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 20px;
	text-align: center;
	background-color: gray;
	font-size: 12px;
	color: #fff;
	padding: 5px 14px;
}
</style>
