<template>
	<div class="hello">
		<div class="top_content">
			<div class="left_icon" @click="gotoback">
				<img src="../assets/back.png" alt="" />
			</div>
			<div class="center_content">发布图片</div>
			<div class="rules" @click="pageroule">发布规则</div>
		</div>
		<div class="inputbox">
			<div class="biaoqian" @click="selecttag">
				<span>{{ selecttext }}</span>
				<div class="biaoqiaoleft">
					<img src="../assets/leftjiantou.png" alt="" />
				</div>
			</div>

			<div class="item">
				<input v-model="pagebox.forumTitle" maxlength="50" class="inputcontent" placeholder="请输入标题(最多50个字)" type="text" />
				
			</div>

			<div class="item" v-if="upimglist.length != 0">
				<div class="title">选择封面图</div>
				<div class="uplist">
					<div @click="handleselectimg(index)" :class="currimgindex == index ? 'up_item select_item' : 'up_item'" v-for="(item, index) in upimglist">
						<img :src="item" alt="" />
					</div>
				</div>
			</div>
		</div>

		<div class="shagnchuangtupian">
			<div class="u_title">
				添加图集
				<p>(最多只能添加8张图片，每张最大1M以内)</p>
			</div>

			<div class="sc_boxboxbox">
				<div class="imglistbox" v-if="urldata.length > 0">
					<div class="img_item" v-for="(item, index) in urldata">
						<div class="shanchuicon" @click="delimg(index)">
							<img style="width: 20px; height: 20px" src="../assets/delicon.png" alt="" />
						</div>
						<img :src="item" alt="" />
					</div>
				</div>

				<label class="topcontent">
					<input @change="handleimglist" multiple accept="image/*" type="file" id="btnAdd" name="btnAdd" style="display: none" />

					<div class="uploadcontent">
						<div class="up_text">添加图片</div>
					</div>
				</label>
			</div>
		</div>

		<div class="tipsbox">
			<p>上传完后需要等待审核</p>
		</div>

		<div class="bottom_button">
			<div class="upbtn" @click="uplofile">上传文件</div>
		</div>

		<div class="popus" v-if="showupbar">
			<p style="margin-bottom: 10px">上传中...</p>
		</div>

		<div class="popus" v-if="zhuanmazhong">
			<p>正在转码中...</p>
		</div>

		<div class="upimgtipstext" v-if="imgerror">请选择要上传的图片</div>
		<div class="uptipstext" v-if="texterrox">请输入帖子信息</div>
		<div class="successtext" v-if="upsuccess">上传成功,等待发布帖子</div>
		<div class="fabutext" v-if="fabusuccess">帖子发布成功,等待审核!</div>
		<div class="errortext" v-if="uperror">上传失败</div>

		<div class="errortext" v-if="upnum">今日上传剩余次数不足</div>
	</div>
</template>
<script>
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import rsa from '@/rsa/rsa.js';
import webview from '@/rsa/webview.js';

export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
		return {
			selecttext: '选择帖子板块',
			uploadstatus: true,
			checkboxs: [],
			yhdata: '',
			currimgindex: 0,
			upimglist: [],
			uploadurl: 'https://videoupload.appinfo.ltd',
			baseurl: 'https://api.appinfo.live',
			filedata: '',
			imgdata: [],
			urldata: [],
			checked: false,
			UserId: '',
			Token: '',
			UserVid: '',
			progressbar: 0,
			upnum: false,
			showupbar: false,
			upsuccess: false,
			uperror: false,
			zhuanmazhong: false,
			fabusuccess: false,
			fabuzhuangtai: true,
			texterrox: false,
			imgerror: false,
			pagebox: {
				forumTitle: '',
				forumContent: '',
				gold: 0,
				taggingId: '',
				videoPreviewURL: '',
				videoM3U8URL: '',
				duration: '',
				imageURL: '',
				forumType: 1
			},
			upimgresarray: []
		};
	},

	watch: {
		'pagebox.gold': {
			handler: function (newValue, oldValue) {
				
				if (newValue > 100) {
					this.pagebox.gold = 0;
				}
			},
			deep: true
		}
	},

	mounted() {
		if (this.$route.params.id) {
			this.pagebox.taggingId = this.$route.params.id;
			this.selecttext = this.$route.params.title;

			this.UserId = this.$route.params.UserId;
			this.Token = this.$route.params.Token;
			this.UserVid = this.$route.params.UserVid;
		}
		if (this.$route.params.UserId) {
			this.UserId = this.$route.params.UserId;
			this.Token = this.$route.params.Token;
			this.UserVid = this.$route.params.UserVid;
		}
	},
	methods: {
		//删除选择的图片
		delimg(indeximg) {
			
			this.imgdata.splice(indeximg, 1);
			this.urldata.splice(indeximg, 1);
		},

		//发布规则
		pageroule() {
			this.$router.push({
				path: '/roule',
				query: {
					type: 0,
					UserId: this.UserId,
					Token: this.Token,
					UserVid: this.UserVid,
					pagetype: 0,
					taggingType: 0
				}
			});
		},

		//返回uniapp
		gotoback() {
			webview.postMessage({
				data: '发布成功'
			});
		},

		//选择标签
		selecttag() {
			this.$router.replace({
				path: '/tablist',
				query: {
					type: 0,
					UserId: this.UserId,
					Token: this.Token,
					UserVid: this.UserVid,
					pagetype: 0,
					taggingType: 0
				}
			});
		},

		//获取上传的图片集合
		handleimglist(e) {
			const imgarray = e.target.files;
			const URL = window.URL || window.webkitURL; // 当前地址
			for (let index in imgarray) {
				if (this.imgdata.length < 9) {
					if (imgarray[index].lastModified) {
						this.imgdata.push(imgarray[index]);
						this.urldata.push(URL.createObjectURL(imgarray[index]));
					}
				}
			}
			
		},

		//获取用户上传信息
		uploaddetails() {
			let _this = this;
			let header = '';
			let InPutData = {
				UserId: this.UserId,
				Token: this.Token,
				Type: '1'
			};
			header = {
				InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
			};

			axios.get(this.baseurl + '/api/UserHandle/GetUserData', { headers: header }).then((res) => {
				if (res.data.code == 200) {
					this.yhdata = res.data.data;
				}
			});
		},

		//获取上传的图片
		handleimgUpload(e) {
			
			const imgarray = e.target.files;

			if (this.imgdata.length < 10) {
				for (let index in imgarray) {
					if (imgarray[index].lastModified) {
						this.imgdata.push(imgarray[index]);
					}
				}
			}

			
		},

		//获取封面文件
		handleimgfengmian(e) {
			
		},

		//获取上传的文件
		handleFileUpload(e) {
			this.filedata = e.target.files[0];
			
		},

		//对视屏进行切片
		slicevideo(file) {
			return new Promise((resolve, reject) => {
				let chunkSize = 10 * 1024 * 1024; // 每个块的大小为 1MB
				let fileSize = file.size; // 文件大小
				let chunks = Math.ceil(fileSize / chunkSize); // 总块数
				let tasks = []; // 切块
				// 文件切割、
				for (let i = 0; i < chunks; i++) {
					let start = i * chunkSize;
					let end = Math.min(start + chunkSize, fileSize);
					tasks.push(file.slice(start, end));
				}
				resolve(tasks);
			});
		},

		//获取文件的md5值
		fileMd5Sum(file) {
			
			return new Promise((resolve) => {
				let fileName = file.name.substring(0, file.name.lastIndexOf('.'));
				
				let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
				
				let fileReader = new FileReader();
				let Spark = new SparkMD5.ArrayBuffer();
				fileReader.readAsArrayBuffer(file);
				fileReader.onload = function (e) {
					
					Spark.append(e.target.result);
					let md5 = Spark.end();
					let fileboxdata = {
						md5: md5,
						fileName: fileName,
						fileExtension: fileExtension
					};
					resolve(fileboxdata);
				};
			});
		},

		//大文件是否存在校验
		jianchefile(status, md5, uploadkey) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: status,
						md5: md5,
						uploadkey: uploadkey
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//大文件的分割的切片是否存在
		jianchefilesoon(chunkIndex, name, size) {
			let that = this;
			return new Promise((resolve, reject) => {
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunkCheck',
						chunkIndex: chunkIndex,
						name: name,
						size: size
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传视频分片
		upvideo(md5, index, fileitem) {
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', index);
				formData.append('file', fileitem);
				axios
					.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//上传图片文件
		upimgfile(md5, fileitem) {
			
			let that = this;
			return new Promise((resolve, reject) => {
				const formData = new FormData();
				formData.append('userId', 'sdqutyeksdbv855');
				formData.append('uniqueFileName', md5);
				formData.append('chunk', 0);
				formData.append('file', fileitem);
				axios
					.post(that.uploadurl + '/uploads', formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						resolve(res);
					});
			});
		},

		//合并视屏切片
		joinvideo(md5, chunkslength, fileName, fileExtension) {
			let that = this;
			return new Promise((resolve, reject) => {
				let obj = {
					status: 'chunksMerge',
					md5: md5,
					chunks: chunkslength,
					name: md5,
					fileoldname: fileName,
					ext: fileExtension
				};
				axios({
					url: that.uploadurl + '/uploads',
					method: 'get',
					params: {
						status: 'chunksMerge',
						md5: md5,
						chunks: chunkslength,
						name: md5,
						fileoldname: fileName,
						ext: fileExtension
					}
				}).then((res) => {
					resolve(res.data);
				});
			});
		},

		//上传文件
		async uplofile() {
			if (this.uploadstatus) {
				this.uploadstatus = false;
				if (this.imgdata.length == 0) {
					this.imgerror = true;
					setTimeout(() => {
						this.imgerror = false;
						this.uploadstatus = true;
					}, 2000);
					return;
				}
				if (!this.pagebox.forumTitle) {
					this.texterrox = true;
					setTimeout(() => {
						this.texterrox = false;
						this.uploadstatus = true;
					}, 2000);
					return;
				}
				this.showupbar = true;
				const imglistfile = this.imgdata;
				this.upimgresarray = [];
				for (let index in imglistfile) {
					//获取图片md5信息
					let imgmd5mesgs = await this.fileMd5Sum(imglistfile[index]);
					//检测视频是否存在 不存在就上传
					let imgfileres = await this.jianchefile('md5Check', imgmd5mesgs.md5, 'sdqutyeksdbv855');
					//上传图片
					let upimgres = await this.upimgfile(imgmd5mesgs.md5, imglistfile[index]);
					//合并上传图片
					let upimgjieguo = await this.joinvideo(imgmd5mesgs.md5, 1, imgmd5mesgs.fileName, imgmd5mesgs.fileExtension);
					if (upimgjieguo.error == 'undefined' || upimgjieguo.error == undefined) {
						let imageURL = upimgjieguo.url;
						let imagestr = imageURL.split('/');
						let imageresult = '';
						for (let i = 3; i < imagestr.length; i++) {
							if (imageresult !== '') {
								imageresult += '/';
							}
							imageresult += imagestr[i];
						}
						this.upimgresarray.push(imageresult);
						
						if (index == imglistfile.length - 1) {
							// this.uploadstatus = true;
							// this.upsuccess = true;
							// setTimeout(() => {
							// 	this.upsuccess = false;
							// }, 10000);

							this.fabuvideo();
						}
					} else {
						this.showupbar = false;
					}
				}
			}
		},

		//选择图片
		handleselectimg(index) {
			this.currimgindex = index;
		},

		//选择标签
		handletab(itemvalue, index) {
			let total = 0;
			let checkboxs = this.checkboxs;
			checkboxs[index].checked = !checkboxs[index].checked;
			for (let souying in checkboxs) {
				if (checkboxs[souying].checked) {
					total = total + 1;
					if (total > 3) {
						checkboxs[souying].checked = false;
					}
				}
			}
		},

		//发布社区
		fabuvideo(itemvalue) {
			
			let _this = this;
			let header = '';
			let InPutData = {
				UserId: _this.UserId,
				Token: _this.Token,
				Type: '1'
			};
			header = {
				InPutData: rsa.rsaEncrypt(JSON.stringify(InPutData))
			};
			axios
				.post(
					_this.baseurl + '/api/UserForum/PublishUserForum',
					{
						forumTitle: this.pagebox.forumTitle,
						videoPreviewURL: '',
						videoM3U8URL: '',
						duration: '',
						imageURL: this.upimgresarray.toString(),
						taggingId: this.pagebox.taggingId,
						forumType: 1,
						forumContent: '',
						gold: this.pagebox.gold
					},
					{
						headers: header
					}
				)
				.then((res) => {
					this.showupbar = false;
					this.uploadstatus = true;
					if (res.data.code == 200) {
						_this.fabusuccess = true;
						setTimeout(() => {
							_this.fabusuccess = false;
						}, 2000);
						setTimeout(() => {
							webview.postMessage({
								data: '发布成功'
							});
						}, 1500);
					} else {
						this.showupbar = false;
						_this.fabuzhuangtai = true;
						_this.uperror = true;
						setTimeout(() => {
							_this.uperror = false;
						}, 2000);
					}
				});
		}
	}
};
</script>
<style scoped>
* {
	padding: 0;
	margin: 0;
}
.hello {
	padding-top: 80px;
	min-height: 91vh;
	overflow-y: scroll;
	background-color: #090216;
	width: 375px;
}
.inputbox{
	width: 325px;
	margin: 100px auto 0 auto;
}
.inputbox .item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	width: 325px;
	
}


.top_content {
	position: fixed;
	left: 0;
	right: 0;
	top: 0px;
	z-index: 99;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 15px;
	flex-direction: row;
	background-color: #090216;
	padding-top: 40px;
}
.left_icon {
	width: 20px;
	height: 20px;
	display: flex;
}
.left_icon img {
	width: 20px;
	height: 20px;
}

.center_content {
	font-size: 20px;
	font-weight: bold;
	padding-left: 110px;
	color: #fff;
}
.rules {
	font-size: 14px;
	position: absolute;
	right: 20px;
	bottom: 10px;
	color: #DD3654;
	font-weight: bold;
}
.shagnchuangtupian{
	margin-left: 20px;
}
.shagnchuangtupian .u_title p {
	color: red;
	font-size: 13px;
	font-weight: normal;
	color: #fff;
	margin-top: 4px;
}
.shagnchuangtupian .u_title {
	font-size: 20px;
	text-align: left;
	padding-bottom: 10px;
	color: #fff;
	font-weight: bold;
	padding-left: 5px;
	position: relative;
	margin-top: 20px;
	
}
.shagnchuangtupian .scboxbox {
	display: flex;
}
.shagnchuangtupian .uploadcontent {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	background-color: #f5f5f5;
	position: relative;
	margin-right: 20px;
}
.uploadcontent::after {
	position: absolute;
	content: '';
	width: 25px;
	height: 2px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.uploadcontent::before {
	position: absolute;
	content: '';
	width: 2px;
	height: 25px;
	background-color: gray;
	left: 50%;
	top: 40px;
	transform: translate(-50%, -50%);
}
.shagnchuangtupian .up_text {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 20px;
	white-space: nowrap;
	font-size: 13px;
	color: #999;
	z-index: 99;
}
.shagnchuangtupian .selected_video {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0px;
	white-space: nowrap;
	font-size: 12px;
	z-index: 99;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 80px;
	color: red;
}
.sc_boxboxbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox {
	display: flex;
	flex-wrap: wrap;
}
.imglistbox .img_item {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;
}
.imglistbox .img_item img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}
.shanchuicon {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	display: flex;
}

.shagnchuangtupian .topcontent {
	width: 100px;
	height: 100px;
	font-size: 14px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	color: #fff;
	border-radius: 5px;
	margin-right: 10px;
}

.shagnchuangtupian {
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	position: relative;
}

.inputcontent {
	width: 305px;
	height: 40px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid #eee;
	border-radius: 20px;
	background-color: #f5f5f5;
}
.textareacontent {
	padding-top: 10px;
	padding-bottom: 10px;
	width: 335px;
	height: 100px;
	padding-left: 10px;
	border: 1px solid #eee;
	border-radius: 5px;
	background-color: #f5f5f5;
	font-size: 13px;
	padding-right: 10px;
}

.biaoqian {
	position: relative;
	display: flex;
	width: 325px;
	height: 40px;
	border: 1px solid #eee;
	border-radius: 20px;
	background-color: #f5f5f5;
	margin-bottom: 30px;
	font-weight: bold;
}
.biaoqian span {
	position: absolute;
	left: 15px;
	font-size: 13px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
}
.biaoqian .biaoqiaoleft {
	position: absolute;
	width: 20px;
	height: 20px;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	img {
		width: 20px;
		height: 20px;
	}
}
.tipsbox {
	display: flex;
	flex-direction: column;
	padding-left: 20px;
}
.tipsbox p {
	font-size: 15px;
	color: #fff;
	text-align: left;
	line-height: 20px;
	display: flex;
	align-items: center;
}
.tipsbox p img {
	width: 12px;
	height: 12px;
	margin-left: 2px;
	display: flex;
}

.bottom_button {
	margin-top: 150px;
}
.bottom_button .upbtn {
	height: 44px;
	border-radius: 16px;
	width: 301px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 16px auto 0 auto;
	background-color: red;
}
.fabushortvideo {
	height: 44px;
	border-radius: 16px;
	width: 301px;
	text-align: center;
	line-height: 44px;
	color: #fff;
	font-size: 16px;
	margin: 16px auto 0 auto;
	background-color: red;
}

.uplist {
	display: flex;
	flex-wrap: wrap;
}
.uplist .up_item {
	width: 60px;
	height: 100px;
	margin-top: 10px;
	margin-left: 5px;
	border: 1px solid #fff;
}
.uplist .select_item {
	border: 1px solid red;
}
.uplist .up_item img {
	width: 60px;
	height: 100px;
}

.popus {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 16px;
	flex-direction: column;
}
.popus .jingdutiao {
	width: 250px;
	height: 12px;
	border: 1px solid #fff;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}
.popus .jingdutiao span {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: gold;
}
.transcoding {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.uptipstext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.upimgtipstext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.successtext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.fabutext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}
.errortext {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 5px;
}

.img_box {
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	color: #666;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 140px;
}
</style>
